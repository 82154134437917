* {
  margin: 0;
  outline: none;
  padding: 0;
  border: 0;
}

html,
body {
  min-height: -webkit-fill-available;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

input,
textarea {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

:root {
  --app-height: 100%;
}

.app {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 100vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;
}

.rsc {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 100vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.rsc.bradesco {
  background-color: #e52645;
}

.rsc.vya {
  background-color: #343a40;
}

.rsc.bradesco::before {
  content: '';
  position: absolute;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 100vh) * 100);
  top: 0;
  left: 0;
  z-index: 1;
  background: #e52645 url('./assets/wave.svg') no-repeat;
  transform: rotate(180deg);
  background-position: bottom;
  background-size: contain;
}

.rsc.vya::before {
  content: '';
  position: absolute;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 100vh) * 100);
  top: 0;
  left: 0;
  z-index: 1;
  background: #343a40 url('./assets/wave.svg') no-repeat;
  transform: rotate(180deg);
  background-position: bottom;
  background-size: contain;
}

.rsc.imasf .rsc-ts-bubble {
  white-space: pre-wrap;
}

.rsc.imasf .rsc-cs,
.--no-padding-top .rsc-cs {
  padding-top: 0;
}

.rsc-link {
  font-weight: 700;
  text-decoration: none;
  color: #fff;
}

.rsc.imasf::before {
  content: '';
  position: absolute;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 100vh) * 100);
  top: 0;
  left: 0;
  z-index: 1;
  background: #0f71b3 url('./assets/wave.svg') no-repeat;
  transform: rotate(180deg);
  background-position: bottom;
  background-size: contain;
}
.rsc.itau::before {
  content: '';
  position: absolute;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 100vh) * 100);
  top: 0;
  left: 0;
  z-index: 1;
  background: #f27405 url('./assets/wave.svg') no-repeat;
  transform: rotate(180deg);
  background-position: bottom;
  background-size: contain;
}
.rsc.stocco::before {
  content: '';
  position: absolute;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 100vh) * 100);
  top: 0;
  left: 0;
  z-index: 1;
  background: #00305a url('./assets/wave.svg') no-repeat;
  transform: rotate(180deg);
  background-position: bottom;
  background-size: contain;
}

.rsc-container {
  width: 400px !important;
  font-family: Roboto, Arial !important;
  background-color: #f5f5f5;
}

.rsc-ts-image {
  background-color: #fff !important;
}

.rsc-ts-user .rsc-ts-bubble {
  color: #524f4f !important;
}

.rsc-content {
  height: calc(100% - 112px) !important;
}

.anchor-link {
  display: none;
}

.rsc-cs {
  box-shadow: none !important;
  background-color: #f5f5f5 !important;
  margin: 0 !important;
}

.rsc-ts-image {
  width: 40px !important;
}

.default__button {
  padding: 10px;
  font-size: 14px;
  background-color: rgb(236, 236, 236);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #333;
}

.default__button svg {
  margin-right: 10px;
}

.default__button:hover {
  background-color: rgb(228, 228, 228);
}

.nice-dates-day.-outside {
  color: #333;
}

.nice-dates-day.-disabled {
  color: #ddd !important;
}

.rsc-ts-bubble {
  word-wrap: break-word;
}

@media screen and (max-width: 568px) {
  .rsc-container {
    width: 100% !important;
    border-radius: 0 !important;
  }

  .date-container {
    width: 80% !important;
  }
}

.link {
  font-weight: bold;
  text-decoration: none;
  color: #fff;
}

.loader-container {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 100vh) * 100);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 9999;
}

.loader-container.close {
  left: -100vw;
}
