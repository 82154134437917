.export-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 550px;
  flex-wrap: wrap;
}

.export-container button {
  min-width: 100px;
  border: 1px solid #fff;
  background-color: #413f43;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;

  border-radius: 25px;
  margin-bottom: 5px;

  cursor: pointer;
  transition: all 0.3s;
  margin-right: 5px;

  animation: baloon 0.3s ease;
  transition: all 0.3s;
}

.export-container button:hover {
  background-color: #515053;
}

.export-container svg {
  margin-right: 5px;
}

.export-container span {
  color: #fff;
}

@keyframes baloon {
  from {
    transform: scale(0);
  }
  ro {
    transform: scale(1);
  }
}
