.date-container {
  width: 100%;
  min-height: 350px;
  box-shadow: 0 0 6px 4px rgba(51, 51, 51, 0.048);
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
}

.nice-dates-popover {
  max-width: 600px;
}

.input,
.input-focused {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 10px;
  transition: all 0.3s;
}

.input.-focused {
  border: 1px solid #333;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 568px) {
  .date-container {
    min-height: 250px;
  }
}
